import React, { useEffect, lazy, Suspense } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHomeDetails } from "../../actions/home";

const Banner = lazy(() => import("./Banner/Banner"));
const SolutionsSection = lazy(() => import("./SolutionsSection/SolutionsSection"));
const ThinkingBlock = lazy(() => import("./ThinkingBlock/ThinkingBlock"));
const LifeSymphony = lazy(() => import("./ThinkingBlock/LifeSymphony"));
const GlobalOffices = lazy(() => import("./GlobalOffices/GlobalOffices"));
const Trending = lazy(() => import("./GlobalOffices/Trending"));
const WeatherZones = lazy(() => import("./WeatherZones/WeatherZones"));
const HomeProductSlider = lazy(() => import("./HomeProductSlider/HomeProductSlider"));
const Statistics = lazy(() => import("./Statistics/Statistics"));

const Home = () => {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  useEffect(() => {
    dispatch(fetchHomeDetails());
    setInterval(() => {
      dispatch(fetchHomeDetails());
    }, 60000 );
  }, [dispatch]);
  if (home.home.is_loading) {
    return (
      <div className="page-loader">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  const renderLoader = () => <p>Loading</p>;
  return (
    <>
      {home && home.home && home.home.seo_data && (
        <Helmet>
          <title>{home.home.seo_data.seo_title}</title>
          <meta
            name="description"
            content={home.home.seo_data.mera_description}
          />
          <meta name="keywords" content={home.home.seo_data.keywords} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={home.home.seo_data.seo_title} />
          <meta
            property="og:description"
            content={home.home.seo_data.mera_description}
          />
          <meta
            property="og:image"
            content={home.home.seo_data.preview_image}
          />
          <meta name="image" content={home.home.seo_data.preview_image} />
        </Helmet>
      )}
      <Suspense fallback={renderLoader()}>
        <Banner home={home} />
      </Suspense>
      <SolutionsSection home={home} />
      <Statistics home={home} />
      <ThinkingBlock home={home} />
      <HomeProductSlider home={home} />
      <WeatherZones home={home} />
      <div className="bg-gray">
        <GlobalOffices home={home} />
        <LifeSymphony home={home} />
      </div>
  
      <Trending
        home={home}
        
      />

      <section className="stop-shop-section">
        <div className="container">
          <h2 className="h2">Why is Symphony a One Stop Shop for Best Air Coolers?</h2>
          <p>Symphony Limited stands out as one of the best air cooler companies in India due to its remarkable track record of innovation, excellence, and commitment to customer satisfaction. With inception, Symphony introduced the concept of evaporative air cooling to India, revolutionizing the market. With a strong presence in over 60 countries, Symphony is the world&apos;s largest manufacturer of air coolers, a testament to its global leadership and expertise in the industry. What sets Symphony apart is its relentless focus on technological advancements, energy efficiency, and environmental stewardship. The company&apos;s air coolers are known for their design innovation and customer-centricity, providing efficient and eco-friendly cooling solutions. Symphony&apos;s dedication to combating climate change and creating sustainable cooling options further solidifies its position as India&apos;s most preferred air cooler company, offering unparalleled value and comfort to its customers.</p>
        </div>
      </section>
    </>
  );
};

export default Home;
